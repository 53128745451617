import { useMemo, useEffect, useState } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { useSliderContext } from 'src/components/common/slider';

/**
 * React hook that tracks navigation related properties of a Slider instance.
 * Listens to slide index changes and returns canSlide(Next|Previous) booleans
 * and slide(Next|Previous) methods.
 */
export default function useSliderNavigation() {
  const { slider } = useSliderContext();
  const [canSlidePrevious, setCanSlidePrevious] = useState(false);
  const [canSlideNext, setCanSlideNext] = useState(false);

  useEffect(() => {
    function handleSlideChange(slider: SwiperClass) {
      setCanSlidePrevious(slider.params.loop || !slider.isBeginning);
      setCanSlideNext(slider.params.loop || !slider.isEnd);
    }

    if (slider) {
      handleSlideChange(slider);
      // re-run the check on each slide change
      slider.on('activeIndexChange', handleSlideChange);
      // re-run the check on each layout change, e.g. when the distances between slides changes
      slider.on('snapGridLengthChange', handleSlideChange);
      slider.on('orientationchange', handleSlideChange);
      slider.on('reachEnd', handleSlideChange);
      return () => {
        slider.off('activeIndexChange', handleSlideChange);
        slider.off('snapGridLengthChange', handleSlideChange);
        slider.off('orientationchange', handleSlideChange);
        slider.off('reachEnd', handleSlideChange);
      };
    }
  }, [slider]);

  return useMemo(
    () => ({
      slidePrevious: () => {
        slider?.slidePrev();
      },
      slideNext: () => {
        slider?.slideNext();
      },
      canSlideNext,
      canSlidePrevious,
    }),
    [slider, canSlideNext, canSlidePrevious],
  );
}
